import {
  getCustomizeControlsActions,
  useCustomizeControlsStore,
} from "@/modules/agent/states/cusomize-controls";
import { convertSnakeToTitleCase } from "@/modules/agent/utils/snake-to-capital";
import { ZButton } from "@/shared/components/button";
import { addNotification } from "@/shared/states/notification";
import { Loader } from "lucide-react";
import { useCallback } from "react";

const CustomizeControlTopRIghtSection = () => {
  const isSavingControls = useCustomizeControlsStore().isSavingControls;
  const selectedControls = useCustomizeControlsStore().selectedControls;
  const searchQuery = useCustomizeControlsStore().searchQuery;
  const selectedTab = useCustomizeControlsStore().selectedTab;
  const newControlPlaceHolderUid = useCustomizeControlsStore()
    .newControlPlaceHolderUid;

  const handleOnClickDeleteButtom = useCallback(() => {
    getCustomizeControlsActions().setDataState((prev) => {
      let newValue = [...prev];

      newValue = newValue.filter((v) => selectedControls.has(v.uid) === false);

      getCustomizeControlsActions().setSelectedControls(new Set<string>());
      getCustomizeControlsActions().setChangesMade((prev) => prev + 1);
      addNotification({
        message: `${selectedControls.size} control${
          selectedControls.size > 1 ? "s" : ""
        } has been removed from ${convertSnakeToTitleCase(selectedTab)}`,
        type: "error",
      });
      return newValue;
    });
  }, [selectedControls, selectedTab]);

  const handleOnClickAddButton = useCallback(() => {
    const el = document.getElementById(`${newControlPlaceHolderUid}tsc_id`);
    if (el) {
      el.focus();
    }
  }, [newControlPlaceHolderUid]);

  return (
    <>
      {isSavingControls === "SAVING" && (
        <span className="flex items-center min-w-fit gap-1">
          <Loader className="w-4 h-4 animate-spin" />
          <p className="text-tiny text-[#A5A5A5] text-[8px]">Saving</p>
        </span>
      )}
      {selectedControls.size > 0 ? (
        <ZButton
          color="danger"
          variant="solid"
          className="text-xs font-medium max-h-7 max-w-32 border-1"
          onClick={handleOnClickDeleteButtom}
          onMouseDown={handleOnClickDeleteButtom}
        >
          Delete Control{selectedControls.size > 1 ? "s" : ""}
        </ZButton>
      ) : (
        searchQuery.trim().length === 0 && (
          <ZButton
            color="primary"
            variant="bordered"
            className="text-xs font-medium max-h-7 max-w-32 border-1"
            onClick={handleOnClickAddButton}
            onMouseDown={handleOnClickAddButton}
          >
            Add Control
          </ZButton>
        )
      )}
    </>
  );
};

export default CustomizeControlTopRIghtSection;
