import { addNotification } from "@/shared/states/notification";
import type { Selection } from "@nextui-org/react";
import {
  Accordion,
  AccordionItem,
  Card,
  CardBody,
  ScrollShadow,
  Select,
  SelectItem,
  Spinner
} from "@nextui-org/react";
import { Minus, PlusIcon } from "lucide-react";
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  lazy,
  type ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  AgentSessionStatus,
  AgentSessionStepType,
  AgentSessionType,
} from "@/modules/sessions/types";
import useMemoAsync from "@/shared/hooks/useMemoAsync.ts";
import { sourcePreviewSupportedFileTypes } from "../../constants/sourcePreviewSupportedFileTypes";
import {
  getAgentData,
  useAgentSelector,
  useAgentSessionData,
  useAgentStepData,
  useSelectedReviewResponse,
} from "../../states";
import { TSCENUM } from "../../states/cusomize-controls.ts";
import useModifiedSelectedReviewResponse, {
  modifyResponseReviewDataConfigV1,
  modifyResponseReviewDataConfigV2,
} from "../../states/use-modified-review-response";
import {
  AGENT_TYPES,
  type AgentSpecificReviewResponseType,
  type AgentSubTypes,
  ComplianceStatus,
  type ExcelReviewSource,
  GapAssessmentTypes,
  type PDFReviewSource,
  type ReviewResponseData,
  type ReviewResponseTableRow,
  ReviewResponseType,
  ReviewSourceTypes,
} from "../../types/index.ts";
import { ResponseSchemaVersion } from "../../types/risk-and-gap.ts";
import { checkAndRefreshUrl } from "../../utils/refreshUrl.ts";
import { convertSnakeToTitleCase } from "../../utils/snake-to-capital.ts";
import AgentEditField from "./AgentEditField";
import AgentEditFieldWithInlineSources from "./AgentEditFieldWithInlineSources";
import CombinedAgentEditField from "./CombinedAgentEditField";
import CombinedAgentEditFieldWithInlineSources from "./CombinedAgentEditFieldWithInlineSources";
import TestResults from "./TestResults.tsx";

const PDFViewer = lazy(() => import("@/shared/components/custom/pdf-preview"));
const JSONPreviewHighlight = lazy(() => import("../json-preview"));
const ExcelPreview = lazy(() => import("../excel-preview"));
const ImageViewer = lazy(
  () => import("@/shared/components/custom/image-viewer"),
);
interface EditSectionProps {
  selectedSourceIndex?: number | null;
  agentId: string;
  agentType: AGENT_TYPES;
  agentSubType: AgentSubTypes;
  expandedView?: boolean;
}

export const supportedSourcePreviewTypes = [
  "application/pdf",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
  "image/svg+xml",
  "image/gif",
  "application/json",
  "text/csv",
  "application/csv",
  "application/vnd.msexcel",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const AgentAccordionItem = ({
  title,
  children,
  indexValue,
  selectedAccordion,
  changeAccordionStatus,
}: {
  title: string;
  children: ReactNode;
  indexValue: number;
  selectedAccordion: Selection;
  changeAccordionStatus: (key: string | number) => void;
}) => (
  <Accordion selectedKeys={selectedAccordion}>
    <AccordionItem
      key={indexValue.toString()}
      classNames={{
        trigger: "p-0",
        base: "p-0",
        title: "text-[#A5A5A5] text-sm cursor-default",
      }}
      startContent={
        <div
          className="p-[1px] border-[1px] rounded-md"
          onClick={() => changeAccordionStatus(indexValue.toString())}
          onKeyDown={() => changeAccordionStatus(indexValue.toString())}
        >
          {[...selectedAccordion].includes(indexValue.toString()) ? (
            <Minus size={16} color="#A5A5A5" />
          ) : (
            <PlusIcon size={16} color="#A5A5A5" />
          )}
        </div>
      }
      indicator={() => <></>}
      title={title}
    >
      {children}
    </AccordionItem>
  </Accordion>
);

const EditSection = ({
  selectedSourceIndex,
  agentId,
  agentType,
  agentSubType,
  expandedView,
}: EditSectionProps) => {
  const { id, data: selectedData } = useSelectedReviewResponse(agentId);
  const type = useAgentSessionData(agentId)?.type

  // Merge some fields to render together
  // 1. gap and recommendation,
  // 2. improvement opportunities and improvement recommendations,
  // 3. maturity level
  // the modConfig depends on the type of "gaps" and "improvement opportunities" fields before the modification (v1 or v2)...
  // if these fields are of type "TEXT" before mod, they should be "COMBINED".
  // if these fields are of type "LIST_OF_TEXT_WITH_SOURCE", they should be "LIST_OF_COMBINED_WITH_SOURCE"

  const schemaVersion = (selectedData?.find((ele) => ele.key === "version")
    ?.value ?? "v1") as ResponseSchemaVersion;

  const modConfig = useMemo(() => {
    switch (schemaVersion) {
      case "v1":
        return modifyResponseReviewDataConfigV1;
      case "v2":
        return modifyResponseReviewDataConfigV2;
      default:
        return modifyResponseReviewDataConfigV1;
    }
  }, [schemaVersion]);

  const modifiedselectedData = useMemo(
    () =>
      useModifiedSelectedReviewResponse(agentSubType, selectedData, modConfig),
    [agentSubType, modConfig, selectedData],
  );

  const [selectedAccordion, setSelectedAccordion] = useState<Selection>(() => {
    const initialSelection = new Set<string>();
    modifiedselectedData?.forEach((_, index) =>
      initialSelection.add(index.toString()),
    );
    if (
      agentType === AGENT_TYPES.RISK_ASSESSMENT ||
      agentType === AGENT_TYPES.GAP_ASSESSMENT
    ) {
      initialSelection.add(modifiedselectedData?.length.toString() ?? "");
    }
    return initialSelection;
  });


  const sourcesRef = useRef<HTMLDivElement>(null);
  const [selectedSource, setSelectedSource] = useState<ReviewSourceTypes>();
  const [listOfSourcesForSelectedSources, setListOfSourcesForSelectedSources] = useState<ReviewSourceTypes[]>();


  const { updateReviewResponse, updateTableRows } = useAgentSelector.useActions();

  useEffect(() => {
    if (typeof selectedSourceIndex === "number" && sourcesRef.current) {
      setTimeout(() => {
        const sourceElements = sourcesRef?.current?.querySelectorAll(
          "[data-source-index]",
        );
        if (sourceElements?.[selectedSourceIndex]) {
          sourceElements[selectedSourceIndex].scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 500);
    }
  }, [selectedSourceIndex]);

  const handleChange = useCallback(
    (data: {
      key: keyof AgentSpecificReviewResponseType<
        typeof agentType,
        typeof agentSubType
      >;
      value: any;
      type?: ReviewResponseType;
    }) => {
      if (!id) return;
      updateReviewResponse<typeof agentType, typeof agentSubType>(agentId, id, {
        key: data.key,
        value: data.value,
      });
    },
    [id, updateReviewResponse, agentId],
  );

  const handleCombinedChange = (agentId: string, id: string, key: keyof ReviewResponseTableRow, value: string) => {
    if (key === 'maturity' as keyof ReviewResponseTableRow) {
      updateTableRows(agentId, id ?? '', key, value);
    }
  }

  const changeAccordionStatus = useCallback((key: string | number) => {
    setSelectedAccordion((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(key.toString())) {
        newSelected.delete(key.toString());
      } else {
        newSelected.add(key.toString());
      }
      return newSelected;
    });
  }, []);

  // Gaps 2. Recommendations 3. Opportunities 4. Recommendations 5. Current Maturity Level 6. Path to Next Maturity Level

  const renderSource = useCallback(async () => {
    if (!selectedSource) {
      return null;
    }

    const {
      source_file_name,
      source_text,
      file_type,
      file_name,
      file_url,
      original_file_name,
    } = selectedSource;
    const sourceFile =
      file_url && file_type && file_name
        ? {
          url: file_url,
          fileType: file_type,
          fileName: file_name,
        }
        : getAgentData<typeof agentType, typeof agentSubType>(
          agentId,
        )?.mainData.sourceFilesUrls.get(
          source_file_name || (file_name ?? ""),
        );

    if (!sourceFile) return null;
    sourceFile.url = await checkAndRefreshUrl(sourceFile.url, file_name || '');
    const handleDownload = () => {
      const link = document.createElement("a");
      link.href = sourceFile.url;
      link.download = sourceFile.fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      setSelectedSource(undefined);
      setListOfSourcesForSelectedSources(undefined);
    };

    if (!sourcePreviewSupportedFileTypes.includes(file_type)) {
      console.error(file_type, "not supported");
      handleDownload();
      return null;
    }

    switch (file_type) {
      case "application/pdf":
        return (
          <Suspense fallback={<Spinner label="Loading..." />}>
            <PDFViewer
              fileUrl={sourceFile.url}
              isOpen={true}
              pageNumber={
                (selectedSource as PDFReviewSource).source_page_number ||
                (selectedSource as PDFReviewSource).page_number
              }
              listOfSourcesForSelectedSources={listOfSourcesForSelectedSources}
              selectedSource={selectedSource}
              setSelectedSource={setSelectedSource}
              onClose={() => {
                setSelectedSource(undefined);
                setListOfSourcesForSelectedSources(undefined);
              }}
              headerTitle={
                original_file_name || source_file_name || (file_name ?? "")
              }
              highlight={
                ((selectedSource as PDFReviewSource).source_highlight_area ||
                  (selectedSource as PDFReviewSource).highlight_area) ??
                []
              }
            />
          </Suspense>
        );
      case "image/jpeg":
      case "image/jpg":
      case "image/png":
      case "image/webp":
      case "image/svg+xml":
      case "image/gif":
        return (
          <Suspense fallback={<Spinner label="Loading..." />}>
            <ImageViewer
              fileUrl={sourceFile.url}
              isOpen={true}
              onClose={() => { setSelectedSource(undefined); setListOfSourcesForSelectedSources(undefined) }}
              headerTitle={source_file_name || (file_name ?? "")}
              listOfSourcesForSelectedSources={listOfSourcesForSelectedSources}
              selectedSource={selectedSource}
              setSelectedSource={setSelectedSource}
            />
          </Suspense>
        );
      case "application/json":
        return (
          <Suspense fallback={<Spinner label="Loading..." />}>
            <JSONPreviewHighlight
              open={true}
              fileUrl={sourceFile.url}
              textToHighlight={source_text ?? ""}
              fileName={source_file_name || (file_name ?? "")}
              onClose={() => {
                setSelectedSource(undefined);
                setListOfSourcesForSelectedSources(undefined);
              }}
              listOfSourcesForSelectedSources={listOfSourcesForSelectedSources}
              selectedSource={selectedSource}
              setSelectedSource={setSelectedSource}
            />
          </Suspense>
        );
      case "text/csv":
      case "application/csv":
      case "application/vnd.msexcel":
      case "application/vnd.ms-excel":
      case "application/vnd.ms-excel.sheet.macroEnabled.12":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": {
        const selectedExcelSource = selectedSource as ExcelReviewSource;
        const isCSV = file_type.includes("csv");
        const hasSheet = selectedExcelSource.sheet_name as any as boolean;
        const invalid = !hasSheet && !isCSV;
        if (invalid) {
          addNotification({
            message: "Error in loading the file",
            type: "error",
          });
          setSelectedSource(undefined);
          setListOfSourcesForSelectedSources(undefined);
          return null;
        }
        return (
          <Suspense fallback={<Spinner label="Loading..." />}>
            <ExcelPreview
              fileUrl={sourceFile.url}
              sheetName={selectedExcelSource.sheet_name ?? ""}
              open={true}
              onClose={() => {
                setSelectedSource(undefined);
                setListOfSourcesForSelectedSources(undefined);
              }}
              highlightRow={
                selectedExcelSource?.source_row_number ||
                selectedExcelSource?.row_number
              }
              listOfSourcesForSelectedSources={listOfSourcesForSelectedSources}
              selectedSource={selectedSource}
              setSelectedSource={setSelectedSource}
            />
          </Suspense>
        );
      }
      default:
        return null;
    }
  }, [agentId, selectedSource, listOfSourcesForSelectedSources, setSelectedSource]);


  const { value: renderedSourceJsx } = useMemoAsync(
    async () => await renderSource(),
    [renderSource]
  );


  const stepData = useAgentStepData(agentId);
  const prepareReportStep = stepData?.find(
    (step) => step.type === AgentSessionStepType.PREPARE_REPORT,
  );
  const isReportComplete =
    prepareReportStep?.status === AgentSessionStatus.COMPLETE;

  const renderEditSection = useCallback(
    (
      sectionData: ReviewResponseData<
        AgentSpecificReviewResponseType<typeof agentType, typeof agentSubType>
      >,
      indexValue: number,
    ) => {
      if (!sectionData.shouldRender) return null;
      if (sectionData.value === undefined) return null;

      switch (sectionData.type) {
        case ReviewResponseType.COMPLIANCE_STATUS:
          if (type && (type === AgentSessionType.SOC2_GAP_TYPE1 || type === AgentSessionType.SOC2_GAP_TYPE2)) {
            return (
              <AgentAccordionItem
                title={sectionData.title}
                indexValue={indexValue}
                selectedAccordion={selectedAccordion}
                changeAccordionStatus={changeAccordionStatus}
              >
                <Select
                  autoFocus={true}
                  aria-label="Select compliance status"
                  placeholder="Select compliance status"
                  classNames={{
                    base: "rounded outline-none shadow-none border bg-[#ffffff]",
                    trigger:
                      "rounded text-[#A5A5A5] text-sm cursor-default bg-transparent",
                  }}
                  selectedKeys={[
                    sectionData.value?.toLowerCase() === "compliant"
                      ? "Fully-Compliant" //for backwards compatibility - can be removed after 1 March 2025
                      : sectionData.value
                        ? sectionData.value.replaceAll(" ", "-")
                        : ComplianceStatus.DNF,
                  ]}
                  isDisabled={isReportComplete}
                  onChange={(selected) => {
                    if (!selected.target.value) {
                      // this is required because our requirement differs from next-ui select's behavior
                      // next-ui select allows the user to click on an already selected option to deselect it...
                      // we need to prevent this, as having an empty value causes it to become null, and shows a NA to the user
                      return;
                    }

                    handleChange({
                      key: sectionData.key as never,
                      value: selected.target.value,
                    });
                    updateTableRows(
                      agentId,
                      id ?? "",
                      "compliant" as keyof ReviewResponseTableRow,
                      agentSubType === GapAssessmentTypes.SOC2
                        ? selected.target.value.replace("-", " ")
                        : selected.target.value,
                    );
                  }}
                >
                  <SelectItem key="No-Exceptions-Noted" value="No-Exceptions-Noted">
                    No Exceptions Noted
                  </SelectItem>
                  <SelectItem
                    key="Exception-Noted"
                    value="Exception-Noted"
                  >
                    Exception Noted
                  </SelectItem>
                </Select>
              </AgentAccordionItem>
            )
          }

          return (
            <AgentAccordionItem
              title={sectionData.title}
              indexValue={indexValue}
              selectedAccordion={selectedAccordion}
              changeAccordionStatus={changeAccordionStatus}
            >
              <Select
                autoFocus={true}
                aria-label="Select compliance status"
                placeholder="Select compliance status"
                classNames={{
                  base: "rounded outline-none shadow-none border bg-[#ffffff]",
                  trigger:
                    "rounded text-[#A5A5A5] text-sm cursor-default bg-transparent",
                }}
                selectedKeys={[
                  sectionData.value?.toLowerCase() === "compliant"
                    ? "Fully-Compliant" //for backwards compatibility - can be removed after 1 March 2025
                    : sectionData.value
                      ? sectionData.value.replace(" ", "-")
                      : ComplianceStatus.DNF,
                ]}
                isDisabled={isReportComplete}
                onChange={(selected) => {
                  if (!selected.target.value) {
                    // this is required because our requirement differs from next-ui select's behavior
                    // next-ui select allows the user to click on an already selected option to deselect it...
                    // we need to prevent this, as having an empty value causes it to become null, and shows a NA to the user
                    return;
                  }

                  handleChange({
                    key: sectionData.key as never,
                    value: selected.target.value,
                  });
                  updateTableRows(
                    agentId,
                    id ?? "",
                    "compliant" as keyof ReviewResponseTableRow,
                    agentSubType === GapAssessmentTypes.SOC2
                      ? selected.target.value.replace("-", " ")
                      : selected.target.value,
                  );
                }}
              >
                <SelectItem key="Fully-Compliant" value="Fully-Compliant">
                  Fully Compliant
                </SelectItem>
                <SelectItem
                  key="Partially-Compliant"
                  value="Partially-Compliant"
                >
                  Partially Compliant
                </SelectItem>
                <SelectItem key="Non-Compliant" value="Non-Compliant">
                  Non Compliant
                </SelectItem>
                <SelectItem key="Data-Not-Found" value="Data-Not-Found">
                  Data Not Found
                </SelectItem>
              </Select>
            </AgentAccordionItem>
          );
        case ReviewResponseType.SOURCE:
          return (
            <AgentAccordionItem
              title={sectionData.title}
              indexValue={indexValue}
              selectedAccordion={selectedAccordion}
              changeAccordionStatus={changeAccordionStatus}
            >
              <Card className="outline-none shadow-none border bg-[#fafafa] rounded-lg">
                <CardBody>
                  <div
                    className="flex flex-col items-start gap-2"
                    ref={sourcesRef}
                  >
                    {(sectionData.value ?? [])
                      .filter(
                        (source: ReviewSourceTypes) => !source.is_inline_source,
                      )
                      .map((source: ReviewSourceTypes, index: number) => (
                        <div
                          key={`${index}_${source.source_file_name}`}
                          className="w-full mt-2 overflow-y-hidden cursor-pointer flex flex-col gap-2 border-l-[2px] p-2 group"
                          onClick={() => {
                            setSelectedSource(source);
                            setListOfSourcesForSelectedSources((sectionData.value ?? [])
                              .filter(
                                (source: ReviewSourceTypes) => !source.is_inline_source,
                              ))
                          }}
                          onKeyDown={() => {
                            setSelectedSource(source);
                            setListOfSourcesForSelectedSources((sectionData.value ?? [])
                              .filter(
                                (source: ReviewSourceTypes) => !source.is_inline_source,
                              ))
                          }}
                          data-source-index={index}
                        >
                          {source.source_text && (
                            <ScrollShadow
                              size={20}
                              hideScrollBar
                              className="pl-2"
                            >
                              <p className="text-small max-h-[200px] transition-colors duration-150 hover:underline hover:text-blue-600">
                                {`"${source.source_text}"`}
                              </p>
                            </ScrollShadow>
                          )}
                          <div className="flex flex-wrap justify-between items-start w-full">
                            <div className="flex flex-wrap gap-3 items-center">
                              <p className="text-tiny text-[#A5A5A5] text-ellipsis">
                                {source.original_file_name ||
                                  source.source_file_name ||
                                  source.file_name}
                              </p>
                              {(source as PDFReviewSource).source_page_number ||
                                (source as PDFReviewSource).page_number ? (
                                <>
                                  <span className="text-tiny text-[#A5A5A5]">
                                    •
                                  </span>
                                  <p className="text-tiny text-[#A5A5A5]">
                                    Page{" "}
                                    {(source as PDFReviewSource)
                                      .source_page_number ||
                                      (source as PDFReviewSource).page_number}
                                  </p>
                                </>
                              ) : null}
                            </div>
                            <i className="text-tiny text-[#A5A5A5] opacity-0 group-hover:opacity-100 duration-150 transition-all ease-linear">
                              {sourcePreviewSupportedFileTypes.includes(
                                source.file_type,
                              )
                                ? "Click to preview"
                                : "Click to download"}
                            </i>
                          </div>
                        </div>
                      ))}
                  </div>
                </CardBody>
              </Card>
            </AgentAccordionItem>
          );
        case ReviewResponseType.TEXT_DISPLAY:
          return (
            <AgentAccordionItem
              title={sectionData.title}
              indexValue={indexValue}
              selectedAccordion={selectedAccordion}
              changeAccordionStatus={changeAccordionStatus}
            >
              <Card className="outline-none shadow-none border bg-[#fafafa]">
                <CardBody
                  className="text-[14px] text-[#44444B] "
                  onClick={() =>
                    addNotification({
                      message: `${sectionData.title} cannot be edited`,
                      type: "error",
                    })
                  }
                >
                  {sectionData.value}
                </CardBody>
              </Card>
            </AgentAccordionItem>
          );
        case ReviewResponseType.TEXT:
          return (
            <AgentAccordionItem
              title={sectionData.title}
              indexValue={indexValue}
              selectedAccordion={selectedAccordion}
              changeAccordionStatus={changeAccordionStatus}
            >
              <AgentEditField
                title={sectionData.title}
                showTitle={false}
                placeholder={`Add ${sectionData.title}`}
                text={sectionData.value?.toString() ?? ""}
                isNumber={false}
                minRows={2}
                maxRows={99999}
                isDisabled={isReportComplete || sectionData.isDisabled}
                onChange={(value) => {
                  handleChange({
                    key: sectionData.key as never,
                    value,
                  });
                }}
              />
            </AgentAccordionItem>
          );
        case ReviewResponseType.CURRENT_MATURITY_LEVEL: {
          const getCurrentMaturityLevel = () => {
            for (const sec of sectionData.value) {
              if (sec.key === 'current_maturity_level') {
                return sec.value;
              }
            }
            return '';
          }
          return (
            <AgentAccordionItem
              title={sectionData.title}
              indexValue={indexValue}
              selectedAccordion={selectedAccordion}
              changeAccordionStatus={changeAccordionStatus}
            >
              <Select
                placeholder="Select Current Maturity Level"
                classNames={{
                  base: "rounded outline-none shadow-none border bg-[#ffffff] mb-2",
                  trigger:
                    "rounded text-[#A5A5A5] text-sm cursor-default bg-transparent",
                }}
                selectedKeys={[getCurrentMaturityLevel()]}
                isDisabled={isReportComplete}
                onChange={(selected) => {
                  if (!selected.target.value) {
                    // this is required because our requirement differs from next-ui select's behavior
                    // next-ui select allows the user to click on an already selected option to deselect it...
                    // we need to prevent this, as having an empty value causes it to become null, and shows a NA to the user
                    return;
                  }

                  handleChange({
                    key: sectionData.key as never,
                    value: selected.target.value,
                  });
                  handleCombinedChange(agentId, id ?? '', "maturity" as keyof ReviewResponseTableRow, selected.target.value);
                }}
              >
                <SelectItem key="Tier 1: Partial" value="Tier 1: Partial">
                  Tier 1: Partial
                </SelectItem>
                <SelectItem
                  key="Tier 2: Risk-Informed"
                  value="Tier 2: Risk-Informed"
                >
                  Tier 2: Risk-Informed
                </SelectItem>
                <SelectItem key="Tier 3: Repeatable" value="Tier 3: Repeatable">
                  Tier 3: Repeatable
                </SelectItem>
                <SelectItem key="Tier 4: Adaptive" value="Tier 4: Adaptive">
                  Tier 4: Adaptive
                </SelectItem>
              </Select>
            </AgentAccordionItem>
          )
        }
        case ReviewResponseType.COMBINED: {
          const combinedFields = sectionData.value.map((singleValue: any) => {
            return {
              key: singleValue.key || '',
              title: singleValue.title,
              placeholder: singleValue.placeholder || '',
              text: (singleValue?.value ?? "").toString(),
              isNumber: false,
              onChange: (value: any) => {
                handleChange({
                  key: singleValue.key as never,
                  value: value,
                });
              },
            };
          });
          return (
            <AgentAccordionItem
              title={sectionData.title}
              indexValue={indexValue}
              selectedAccordion={selectedAccordion}
              changeAccordionStatus={changeAccordionStatus}
            >
              <CombinedAgentEditField
                fields={combinedFields.filter(
                  (field: any) => field?.text !== undefined,
                )}
                showHorizontal={!!expandedView}
                isDisabled={isReportComplete}
              />
            </AgentAccordionItem>
          );
        }
        case ReviewResponseType.NUMBER: {
          return (
            <AgentAccordionItem
              title={sectionData.title}
              indexValue={indexValue}
              selectedAccordion={selectedAccordion}
              changeAccordionStatus={changeAccordionStatus}
            >
              <AgentEditField
                title={sectionData.title}
                placeholder={`Add ${sectionData.title}`}
                showTitle={false}
                text={sectionData.value?.toString() ?? ""}
                isNumber={true}
                isDisabled={isReportComplete}
                onChange={(value) => {
                  // FIXME: This is oddly specific considering the attempt probably was to a very general rendering/editing component.
                  // FIXME: So either we should stop dealing with all these abstractions or we start folllowing the patterm more strictly than this.
                  if ((sectionData.key as any) === "risk_value") {
                    const numValue = value === "" ? null : Number(value);
                    let newValue = value;

                    if (numValue !== null) {
                      if (numValue < 1) {
                        addNotification({
                          title: `Invalid ${sectionData.title}`,
                          message:
                            "Value cannot be less than 1. Setting value to 1.",
                          type: "warn",
                        });
                        newValue = "1";
                      } else if (numValue > 5) {
                        addNotification({
                          title: `Invalid ${sectionData.title}`,
                          message: "Value cannot exceed 5. Setting value to 5.",
                          type: "warn",
                        });
                        newValue = "5";
                      }
                    }

                    // biome-ignore lint/style/noNonNullAssertion: <explanation>
                    updateTableRows(
                      agentId,
                      id!,
                      "riskValue" as keyof ReviewResponseTableRow,
                      newValue,
                    );

                    handleChange({
                      key: sectionData.key as never,
                      value: newValue,
                    });

                    // Force update the input value
                    return newValue;
                  }

                  handleChange({
                    key: sectionData.key as never,
                    value,
                  });
                }}
              />
            </AgentAccordionItem>
          );
        }
        case ReviewResponseType.TEXT_WITH_SOURCE: {
          return (
            <AgentAccordionItem
              title={sectionData.title}
              indexValue={indexValue}
              selectedAccordion={selectedAccordion}
              changeAccordionStatus={changeAccordionStatus}
            >
              <AgentEditFieldWithInlineSources
                fieldValueObj={sectionData.value}
                expandedView={expandedView}
                minRows={2}
                maxRows={999999}
                onSourceClick={setSelectedSource}
                setListOfSourcesForSelectedSources={setListOfSourcesForSelectedSources}
                isDisabled={isReportComplete}
                onChange={(value) => {
                  handleChange({
                    key: sectionData.key as never,
                    value,
                  });
                }}
                placeholder={`Add ${sectionData.title}`}
              />
            </AgentAccordionItem>
          );
        }
        case ReviewResponseType.LIST_OF_COMBINED_WITH_SOURCE: {
          const combinedFields = sectionData.value.map((singleValue: any) => ({
            value: singleValue.value,
            title: singleValue.title,
            placeholder: singleValue.placeholder || '',
            onChange: (value: any) => {
              handleChange({
                key: singleValue.key as never,
                value,
              });
            },
          }));

          return (
            <AgentAccordionItem
              key={`${agentId}_${sectionData.title}`}
              title={sectionData.title}
              indexValue={indexValue}
              selectedAccordion={selectedAccordion}
              changeAccordionStatus={changeAccordionStatus}
            >
              <CombinedAgentEditFieldWithInlineSources
                fields={combinedFields}
                showHorizontal={!!expandedView}
                onSourceClick={setSelectedSource}
                setListOfSourcesForSelectedSources={setListOfSourcesForSelectedSources}
                isDisabled={isReportComplete}
              />
            </AgentAccordionItem>
          );
        }
        case ReviewResponseType.TEST_RESULTS: {
          return (
            <AgentAccordionItem
              key={`${agentId}_${sectionData.title}`}
              title={sectionData.title}
              indexValue={indexValue}
              selectedAccordion={selectedAccordion}
              changeAccordionStatus={changeAccordionStatus}
            >
              <TestResults
                handleChanges={(newValues) => {
                  handleChange({
                    key: sectionData.key as never,
                    value: newValues
                  })
                }}
                onSourceClick={setSelectedSource}
                setListOfSourcesForSelectedSources={setListOfSourcesForSelectedSources}
                values={sectionData.value}
                expandedView={expandedView}
              />
            </AgentAccordionItem>
          )
        }
      }
    },
    [
      selectedAccordion,
      changeAccordionStatus,
      expandedView,
      handleChange,
      updateTableRows,
      agentId,
      id,
      agentSubType,
      isReportComplete,
    ],
  );

  if (!selectedData) return null;
  if (!modifiedselectedData) return null;

  return (
    <>
      <div className="w-full bg-[#FAFAFA] overflow-auto flex flex-col gap-2">
        {agentType === AGENT_TYPES.RISK_ASSESSMENT ? (
          <AgentAccordionItem
            title="Function & Category"
            indexValue={modifiedselectedData.length}
            selectedAccordion={selectedAccordion}
            changeAccordionStatus={changeAccordionStatus}
          >
            <Card className="outline-none shadow-none border rounded-[6px] bg-[#fafafa]">
              <CardBody className="text-[14px] flex flex-row gap-2 p-0">
                <div className="grow flex-1 mr-[1px] text-[#44444B]  border-r p-2 px-3">
                  {selectedData.find((item) => item.key === "function")?.value}
                </div>
                <div className="grow flex-1 text-[#44444B]   p-2">
                  {selectedData.find((item) => item.key === "category")?.value}
                </div>
              </CardBody>
            </Card>
          </AgentAccordionItem>
        ) : null}
        {agentType === AGENT_TYPES.GAP_ASSESSMENT &&
          (agentSubType === GapAssessmentTypes.SOC2 || agentSubType === GapAssessmentTypes.SOC2_TYPE1 || agentSubType === GapAssessmentTypes.SOC2_TYPE2) ? (
          <AgentAccordionItem
            title="Trust Services Criteria & Category"
            indexValue={modifiedselectedData.length}
            selectedAccordion={selectedAccordion}
            changeAccordionStatus={changeAccordionStatus}
          >
            <Card className="outline-none shadow-none border rounded-[6px] bg-[#fafafa]">
              <CardBody className="text-[14px] flex flex-row gap-2 p-0">
                <div className="grow flex-1 mr-[1px] text-[#44444B]  border-r p-2 px-3">
                  <span className="font-medium text-[#44444B]">
                    Trust Services Criteria:
                    <br />
                  </span>
                  {selectedData.find((item) => item.key === "tsc")?.value === TSCENUM.SECURITY
                    ? "Security / Common Criteria"
                    : (selectedData.find((item) => item.key === "tsc")?.value?.split(" ") ?? []).map((d: string) => convertSnakeToTitleCase(d)).join(" ")}
                </div>
                <div className="grow flex-1 text-[#44444B]   p-2">
                  <span className="font-medium text-[#44444B]">
                    Category:
                    <br />
                  </span>
                  {selectedData.find((item) => item.key === "category")?.value}
                </div>
              </CardBody>
            </Card>
          </AgentAccordionItem>
        ) : null}
        {agentType === AGENT_TYPES.GAP_ASSESSMENT &&
          agentSubType === GapAssessmentTypes.DORA ? (
          <AgentAccordionItem
            title="Chapter & Article"
            indexValue={selectedData.length}
            selectedAccordion={selectedAccordion}
            changeAccordionStatus={changeAccordionStatus}
          >
            <Card className="outline-none shadow-none border rounded-[6px] bg-[#fafafa]">
              <CardBody className="text-[14px] flex flex-row gap-2 p-0">
                <div className="grow flex-1 mr-[1px] text-[#44444B]  border-r p-2 px-3">
                  <span className="font-medium text-[#44444B]">
                    Chapter:
                    <br />
                  </span>
                  {selectedData.find((item) => item.key === "chapter")?.value}
                </div>
                <div className="grow flex-1 text-[#44444B]   p-2">
                  <span className="font-medium text-[#44444B]">
                    Article:
                    <br />
                  </span>
                  {selectedData.find((item) => item.key === "article")?.value}
                </div>
              </CardBody>
            </Card>
          </AgentAccordionItem>
        ) : null}
        {modifiedselectedData.map((item, index: number) =>
          renderEditSection(
            item as unknown as ReviewResponseData<
              AgentSpecificReviewResponseType<
                typeof agentType,
                typeof agentSubType
              >
            >,
            index,
          ) ? (
            <div key={`${item.key}-${index}`} id={`${item.key}-${index}`}>
              {renderEditSection(
                item as unknown as ReviewResponseData<
                  AgentSpecificReviewResponseType<
                    typeof agentType,
                    typeof agentSubType
                  >
                >,
                index,
              )}
            </div>
          ) : null,
        )}
      </div>
      {renderedSourceJsx}
    </>
  );
};

export default EditSection;
