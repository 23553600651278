import { AgentSubTypes, ReviewResponseType } from "../types/index.ts";
import { convertSnakeToTitleCase } from "../utils/snake-to-capital";

interface ResponseConfigChild {
  key: string;
  title: string;
  type: string;
  placeholder?: string;
}

interface ResponseConfigParent {
  [key: string]: {
    title: string;
    type: string;
    children: ResponseConfigChild[];
  };
}

interface ResponseConfig {
  [key: string]: ResponseConfigParent;
}

const Soc2ModifyResponseReviewDataConfigV2 = {
  gaps: {
    title: "Gaps",
    type: ReviewResponseType.LIST_OF_COMBINED_WITH_SOURCE,
    children: [
      {
        key: "gaps",
        title: "GAPS",
        placeholder: "gap",
        type: ReviewResponseType.TEXT_WITH_SOURCE,
      },
      {
        key: "recommendations",
        title: "RECOMMENDATIONS",
        placeholder: "recommendation",
        type: ReviewResponseType.TEXT_WITH_SOURCE,
      },
    ],
  },
  improvement_opportunities: {
    title: "Improvement Opportunities",
    type: ReviewResponseType.LIST_OF_COMBINED_WITH_SOURCE,
    children: [
      {
        key: "improvement_opportunities",
        title: "OPPORTUNITIES",
        placeholder: "opportunity",
        type: ReviewResponseType.TEXT_WITH_SOURCE,
      },
    ],
  },
};

// Note: The plan is to eventually send the config from the backend so that changing how a field is displayed is easier and not hardcoded into the rendering logic.
export const modifyResponseReviewDataConfigV2: ResponseConfig = {
  "nist-csf-2": {
    gaps: {
      title: "Gaps",
      type: ReviewResponseType.LIST_OF_COMBINED_WITH_SOURCE,
      children: [
        {
          key: "gaps",
          title: "GAPS",
          placeholder: "gap",
          type: ReviewResponseType.TEXT_WITH_SOURCE,
        },
        {
          key: "recommendations",
          title: "RECOMMENDATIONS",
          placeholder: "recommendation",
          type: ReviewResponseType.TEXT_WITH_SOURCE,
        },
      ],
    },
    improvement_opportunities: {
      title: "Improvement Opportunities",
      type: ReviewResponseType.LIST_OF_COMBINED_WITH_SOURCE,
      children: [
        {
          key: "improvement_opportunities",
          title: "OPPORTUNITIES",
          placeholder: "opportunity",
          type: ReviewResponseType.TEXT_WITH_SOURCE,
        },
        {
          key: "improvement_recommendations",
          title: "RECOMMENDATIONS",
          placeholder: "recommendation",
          type: ReviewResponseType.TEXT_WITH_SOURCE,
        },
      ],
    },
    current_maturity_level: {
      title: "Current Maturity Level",
      type: ReviewResponseType.CURRENT_MATURITY_LEVEL,
      children: [
        {
          key: "current_maturity_level",
          title: "CURRENT MATURITY LEVEL",
          type: ReviewResponseType.TEXT,
        },
      ],
    },
    maturity_details: {
      title: "Maturity Details",
      type: ReviewResponseType.COMBINED,
      children: [
        {
          key: "description_of_current_maturity_level",
          title: "DESCRIPTION",
          type: ReviewResponseType.TEXT,
        },
        {
          key: "path_to_next_maturity_level",
          title: "PATH TO NEXT MATURITY LEVEL",
          type: ReviewResponseType.TEXT,
        },
      ],
    },
  },
  "soc-2": Soc2ModifyResponseReviewDataConfigV2,
  "soc-2-type-2": Soc2ModifyResponseReviewDataConfigV2,
  "soc-2-type-1": Soc2ModifyResponseReviewDataConfigV2,
};

const Soc2ModifyResponseReviewDataConfigV1 = {
  gaps: {
    title: "Gaps",
    type: ReviewResponseType.LIST_OF_COMBINED_WITH_SOURCE,
    children: [
      {
        key: "gaps",
        title: "Gaps",
        placeholder: "gap",
        type: ReviewResponseType.TEXT_WITH_SOURCE,
      },
      {
        key: "recommendations",
        title: "Recommendations",
        placeholder: "recommendation",
        type: ReviewResponseType.TEXT_WITH_SOURCE,
      },
    ],
  },
  improvement_opportunities: {
    title: "Improvement Opportunities",
    type: ReviewResponseType.LIST_OF_COMBINED_WITH_SOURCE,
    children: [
      {
        key: "improvement_opportunities",
        title: "OPPORTUNITIES",
        placeholder: "opportunity",
        type: ReviewResponseType.TEXT_WITH_SOURCE,
      },
    ],
  },
};
export const modifyResponseReviewDataConfigV1: ResponseConfig = {
  "nist-csf-2": {
    gaps: {
      title: "GAPS",
      type: ReviewResponseType.COMBINED,
      children: [
        {
          key: "gaps",
          title: "GAPS",
          placeholder: "gap",
          type: ReviewResponseType.TEXT,
        },
        {
          key: "recommendations",
          title: "RECOMENDATIONS",
          placeholder: "recommendation",
          type: ReviewResponseType.TEXT,
        },
      ],
    },
    improvement_opportunities: {
      title: "Improvement Opportunities",
      type: ReviewResponseType.COMBINED,
      children: [
        {
          key: "improvement_opportunities",
          title: "OPPORTUNITIES",
          placeholder: "opportunity",
          type: ReviewResponseType.TEXT,
        },
        {
          key: "improvement_recommendations",
          title: "RECOMENDATIONS",
          placeholder: "recommendation",
          type: ReviewResponseType.TEXT,
        },
      ],
    },
    current_maturity_level: {
      title: "Current Maturity Level",
      type: ReviewResponseType.CURRENT_MATURITY_LEVEL,
      children: [
        {
          key: "current_maturity_level",
          title: "CURRENT MATURITY LEVEL",
          type: ReviewResponseType.TEXT,
        },
      ],
    },
    maturity_details: {
      title: "Maturity Details",
      type: ReviewResponseType.COMBINED,
      children: [
        {
          key: "description_of_current_maturity_level",
          title: "DESCRIPTION",
          type: ReviewResponseType.TEXT,
        },
        {
          key: "path_to_next_maturity_level",
          title: "PATH TO NEXT MATURITY LEVEL",
          type: ReviewResponseType.TEXT,
        },
      ],
    },
  },
  "soc-2": Soc2ModifyResponseReviewDataConfigV1,
  "soc-2-type-2": Soc2ModifyResponseReviewDataConfigV1,
  "soc-2-type-1": Soc2ModifyResponseReviewDataConfigV1,
};

const applyConfigToArray = (data: any[], currentConfig: ResponseConfigParent) => {
  const modifiedData: any[] = [...data];
  Object.entries(currentConfig).forEach(([mainKey, conf]) => {
    const nestedValues: any[] = [];

    conf.children.forEach((child) => {
      const index = modifiedData.findIndex((row) => row.key === child.key);

      const title = child.title ?? convertSnakeToTitleCase(child.key);

      if (index !== -1) {
        nestedValues.push({
          title,
          type: child.type,
          key: child.key,
          placeholder: child.placeholder,
          value: modifiedData[index].value,
          shouldRender: modifiedData[index].shouldRender,
        });

        if (child.key !== mainKey) {
          modifiedData.splice(index, 1); // remove the paired subKey from the data if it's not the main key
        }
      }
    });

    if (nestedValues.length) {
      const mainKeyIndex = modifiedData.findIndex((row) => row.key === mainKey);
      // replace the data at the mainKeyIndex with the nestedValues
      const title = conf.title ?? convertSnakeToTitleCase(mainKey);

      if (mainKeyIndex === -1) {
        modifiedData.push({
          type: conf.type,
          value: nestedValues,
          key: mainKey,
          title,
          shouldRender: true,
        });
      } else {
        modifiedData.splice(mainKeyIndex, 1, {
          type: conf.type,
          value: nestedValues,
          key: mainKey,
          title,
          shouldRender: true,
        });
      }
    }
  });
  return modifiedData;
};

/** This function returns a version of the selected data with some mofifications to the data structure.
 * The modifications are specified in the modifyResponseConfig object.
 * The function takes in the id of the agent and the agentSubType as parameters.
 * It returns the modified data if the id and agentSubType are valid, otherwise it returns the original data.
 *
 * @param id - The id of the agent.
 * @param agentSubType - The agentSubType of the agent.
 * @param config - The configuration object for modifying the data structure.
 * @returns The modified data if the id and agentSubType is present in the config object, otherwise it returns the original data.
 **/
const useModifiedSelectedReviewResponse = (
  agentSubType: AgentSubTypes,
  selectedData?: any[],
  config: ResponseConfig = modifyResponseReviewDataConfigV1,
) => {
  if (!selectedData) return selectedData;
  if (!agentSubType) return selectedData;
  if (!config[agentSubType]) return selectedData;

  return applyConfigToArray(selectedData, config[agentSubType as string]);
};

export default useModifiedSelectedReviewResponse;
